<template>
  <div class="Footer">
    <div class="aboutBox">
      <div class="aboutDL1" style="background-color: #30343e">
        <div class="autowidth">
          <dl class="pc-show">
            <dt>关于我们</dt>
            <dd>
              <router-link to="/details">同云简介</router-link>
            </dd>
            <dd>
              <router-link to="/Boss">董事长简介</router-link>
            </dd>
            <dd style="height: 30px"></dd>
          </dl>
          <dl class="connect">
            <dt>联系我们</dt>
            <dd>联系热线：19209206475</dd>
            <dd>联系邮箱：taojie1@taeyao.net</dd>
            <dd>联系地址：湖南省长沙市天心区新岭路78号</dd>
          </dl>
          <dl class="pc-show">
            <dt>活动中心</dt>
            <dd>裂变活动</dd>
            <dd style="height: 30px"></dd>
            <dd style="height: 30px"></dd>
            <!-- <dd>
              同云大课堂
              <a href="https://cis.tyzsls.com/news/newsList" target="_blank" rel="noopener noreferrer"></a>
            </dd> -->
          </dl>
          <dl class="code-img">
            <dt><img src="../assets/images/ewm1.jpg" alt="" /></dt>
            <dd class="txtCenter">扫一扫  <br/>关注“同云健康”微信公众号</dd>
          </dl>
          <dl class="code-img">
            <dt><img src="../assets/images/ewm2.jpg" alt="" /></dt>
            <dd class="txtCenter">扫一扫  <br/>添加客服专员企微</dd>
          </dl>
        </div>
      </div>
      <div class="aboutDL2">
        <span>湖南同云医疗科技有限公司</span>
        <span style="margin-left: 10px"
          >备案号：<a href="https://beian.miit.gov.cn/" target="_blank">{{ record }}</a></span
        >
        <span style="margin-left: 10px" v-if="isTyyltech"
          >公安机关备案号：<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43011102002211" target="_blank"
            >43011102002211</a
          ></span
        >
      </div>
    </div>
    <footer class="fixedFooter">
      <el-button>
        <a href="tel:19209206475">电话咨询</a>
      </el-button>
      <el-button type="primary" class="mobileApplyBtn" @click="$router.push({ path: '/apply' })" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    record() {
      return window.location.host == 'www.tyzsls.com' ? '湘ICP备18024112号-1' : '湘ICP备2022009021号-1'
    },
    isTyyltech() {
      return window.location.host == 'www.tyyltech.com'
    }
  }
}
</script>

<style lang="scss" scoped>
.aboutBox {
  background: #30343e;
  color: #fff;
  .autowidth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 10px;
  }
  dt {
    font-size: 18px;
    line-height: 30px;
    text-align: left;
  }
  dd {
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
  img {
    width: 150px;
  }
  .aboutDL2 {
    background: #6f6f6f;
    line-height: 40px;
    text-align: center;
  }
  .code-img dd {
    text-align: center;
  }
  .code-img dt{
    text-align: center;
  }
}
.fixedFooter {
  display: none;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  border-top: 1px solid #ccc;
  padding: 1% 0;
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .aboutBox {
    img {
      width: 90%;
    }
    .connect {
      text-align: left;
      width: 480px;
    }
  }
  .pc-show {
    display: none;
  }
  .aboutBox .aboutDL2 {
    background: #fff;
    color: #000;
    padding-bottom: 20%;
  }
  .fixedFooter {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3% 10%;
    box-sizing: border-box;
    .el-button {
      width: 40%;
    }
    .mobileApplyBtn {
      width: 55%;
      border: none;
      padding: 8%;
      background: url('../assets/images/bottomApplyBtn.png') no-repeat center center;
      background-size: 100% auto;
    }
  }
  .autowidth {
    max-width: 94%;
  }
}
</style>
