<template>
  <section class="Apply">
    <div :class="{ applyMain: true, isApplet: isApplet }" class="" style="background: #fafafa">
      <div class="autowidth applyDL">
        <h3 class="font30 txtCenter marginBot1">免费试用申请</h3>
        <el-form ref="elForm" :rules="rules" :model="formData">
          <el-form-item prop="phone">
            <el-input v-model="formData.phone" placeholder="请填写手机号码">
              <template slot="prepend">手机号*</template>
            </el-input>
          </el-form-item>
          <el-form-item class="code-dorm-item" prop="code">
            <el-input v-model="formData.code" placeholder="请填验证码">
              <template slot="prepend">验证码*</template>
            </el-input>
            <el-button v-show="showSMS" type="primary" :disabled="!showSMS" :loading="codeLoading" @click="onGetCode">获取验证码</el-button>
            <div class="countText" v-show="!showSMS">{{ count }} 秒</div>
          </el-form-item>
          <el-form-item prop="requestName">
            <el-input v-model="formData.requestName" placeholder="请填写联系人">
              <template slot="prepend">联系人*</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="clinicName">
            <el-input v-model="formData.clinicName" placeholder="请填写诊所名称">
              <template slot="prepend">诊所名称*</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="checked" style="text-align: left">
            <el-checkbox v-model="formData.checked">同意</el-checkbox>
            <el-button type="text" @click="open1 = true">《个人信息授权与保护申明》</el-button>
          </el-form-item>
          <p class="txtCenter marginBot1">免费试用 | 1v1专属管家服务 | 产品讲解</p>
          <p class="txtCenter marginBot1" style="color: #fb2d2d">*您也可以拨打电话<a href="tel:19209206475">19209206475</a>联系我们</p>
          <el-form-item>
            <el-button class="marginTop2" type="primary" :loading="applyLoading" @click="onApply">确认提交申请</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Footer />

    <el-dialog :visible="open1" title="个人信息授权与隐私政策" @close="open1 = false">
      <p style="font-size: 16px; text-align: left">
        尊敬的用户，您同意本台在法律允许的范围内，收集您输入的信息，且只有您点击提交按钮之后信息才会传递给商家使用。本平台非常重视用户信息的保护，详见
        <el-button type="text" @click="open2 = true">《隐私政策》</el-button>
      </p>
    </el-dialog>
    <el-dialog
      class="dialog2"
      :visible="open2"
      title="隐私政策"
      @close="
        open1 = false
        open2 = false
      "
    >
      <p>
        我们致力于保护您在使用我们网站时所提供的私隐、私人资料以及个人的资料(统称“个人资料”)，使我们在收集、使用、储存和传送个人资料方面符合(与个人资料私隐有关的法律法规)及消费者保护方面的最高标准。为确保您对我们在处理个人资料上有充分信心，您切要详细阅读及理解隐私政策的条文。
      </p>
      <p>
        特别是您一旦使用我们的网站，将被视为接受、同意、承诺和确认;您在自愿下连同所需的同意向我们披露个人资料;您会遵守本隐私政策的全部条款和限制;您在我们的网站上作登记、资料会被收集;您同意日后我们对隐私政策的任何修改;您同意我们的分公司、附属公司、雇员、就您可能会感兴趣的产品和服务与您联络(除非您已经表示不想收到该等讯息)。被收集的个人资料的种类经您的同意，我们会收集、管理和监控个人资料。
      </p>
      <p>
        为了向您提供我们的各项服务，您需要提供个人资料信息，其中包括个人资料和不具名的资料，包括但不限于:个人资料（您的姓名、电话号码、诊所名称)。
      </p>
      <p>
        收集个人资料及不具名的资料的目的及用途如下:通过我们的网站向您提供我们的各项服务;当您使用我们的网站时，能辨认以及确认您的身份;让您使用我们的网站时得到为您而设的服务;我们的顾客服务人员有需要时可以与您联系;统计我们网站使用量的数据;让您在使用我们网站时更方便;为改进我们的产品、服务及网站内容而进行市场研究调查;为我们搞的活动、市场销售和推广计划收集资料;遵守法律、政府和监管机关的规定，包括但不限于对个人资料披露及通知的规定;就我们提供的各项服务、分析、核对或审查您的信用、付款或地位;处理在您要求下的任何付款指示，直接扣帐或信用安排;使您能运作您的账户以及使我们能从账户支取尚欠的服务费;您提供给我们的个人资料及不具名资料，只保留到搜集的目的已达到的时候，除非应适用的法律法规之规定而继续保留。个人资料的拥有权及披露在我们网站上所搜集的一切资料都由我们所拥有,不会出租或出售给任何无关的第三方。
      </p>
      <p>
        您有权:查询我们是否持有您的任何个人资料;接达我们所持有的您的个人资料;要求我们更正任何不正确的个人资料;不时地征询有关我们所持有的个人资料的性质，政策和执行方法;然而在法律允许的极端有限的情况下，我们可以不允许您接达您的个人资料,例如:如您接达及得到您个人资料可能会对您有危险;当您的个人资料可能会影响一项正在进行的调查;当您的个人资料涉及到法庭程序,并且可能受到发现的限制;当您的个人资料涉及一项商业上敏感的决策过程;当另外一个人的个人资料也包含在同一份记录中;若您欲接达或更正个人资料，或索取有关个人资料的政策、执行方法和被持有的个人资料的种类，应致函到我们的下列的地址;要求接达或更正资料可能要付合理的处理费用;安全保管您的密码，除了我们致力确保您的个人资料存放和处理的安全外，您不应向任何人披露您的登录密码或帐户资料，以保护您的个人资料。
      </p>
      <p>每当您登录我们网站时,尤其是当您使用他人的电脑或者是公共的互联网终端机时，请记着操作完毕后一定要点击退出。</p>
      <p>您的努力和协助对于我们保护您的个人资料绝对有帮助。</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onPrivacy">确认提交申请</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Apply',
  components: {
    Footer
  },
  data() {
    return {
      formData: {
        phone: '',
        code: '',
        requestName: '',
        clinicName: '',
        checked: true
      },
      rules: {
        phone: [{ required: true, message: '请填写手机号码' }],
        code: [{ required: true, message: '请填写验证码' }],
        requestName: [{ required: true, message: '请填写联系人' }],
        clinicName: [{ required: true, message: '请填写诊所名称' }]
      },
      showSMS: true,
      count: 60,
      timer: null,
      codeLoading: false,
      applyLoading: false,
      open1: false,
      open2: false
    }
  },
  computed: {
    isApplet() {
      return this.$route.query.source === 'applet'
    }
  },
  watch: {
    count(val) {
      if (val === 0) {
        this.showSMS = true
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  methods: {
    onApply() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          if (!this.formData.checked) {
            return this.$alert('请勾选同意《个人信息授权与保护申明》协议', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                })
              }
            })
          }

          let { checked, ...submitData } = this.formData
          this.applyLoading = true
          let sessionStorageSource = JSON.parse(sessionStorage.getItem('source'))
          this.axios({
            url: '/system/tclinicrequest/add',
            method: 'post',
            data: {
              ...submitData,
              source: sessionStorageSource ? (sessionStorageSource == 'applet' ? '企宣' : sessionStorageSource) : '官网'
            }
          })
            .then(() => {
              this.$message({
                type: 'success',
                message: '申请成功',
                offset: 90
              })
            })
            .finally(() => {
              this.applyLoading = false
            })
        } else {
          return false
        }
      })
    },
    onGetCode() {
      this.$refs.elForm.validateField('phone', phoneError => {
        if (!phoneError) {
          this.codeLoading = true
          this.$axios({
            url: '/clinic/sms/send',
            params: {
              phone: this.formData.phone
            }
          })
            .then(() => {
              this.$message.success('发送成功')
              const TIME_COUNT = 60
              this.count = TIME_COUNT
              this.showSMS = false
              this.timer = setInterval(() => {
                this.count--
              }, 1000)
            })
            .finally(() => {
              this.codeLoading = false
            })
        }
      })
    },
    onPrivacy() {
      this.open1 = false
      this.open2 = false
      this.formData.checked = true
      this.onApply()
    }
  }
}
</script>

<style lang="scss">
body {
  background: rgb(250, 250, 250);
}
</style>
<style lang="scss" scoped>
.applyMain {
  padding-top: 80px;
  height: calc(100vh - 514px);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 514px;
}
.applyDL {
  width: 720px;
  height: auto;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(148, 148, 148, 0.35);
  border-radius: 15px;
  padding: 10px 70px;
  box-sizing: border-box;
  /deep/ .el-input-group__prepend {
    min-width: 5em;
    padding: 0 10px;
  }
}
/deep/ .el-form-item__error {
  padding-left: 90px;
}
.code-dorm-item {
  /deep/ {
    .el-form-item__content {
      display: flex;
    }
    .el-button {
      margin-left: 10px;
    }
    .countText {
      width: 100px;
      margin-left: 10px;
    }
  }
}
.dialog2 /deep/ {
  .el-dialog__body {
    max-height: 40vh;
    overflow: hidden;
    overflow-y: auto;
    p {
      font-size: 16px;
      text-align: left;
      text-indent: 2em;
      margin-bottom: 5px;
      line-height: 1.5;
    }
  }
  .dialog-footer {
    text-align: center;
  }
}
@media only screen and (max-width: 640px) {
  .applyDL {
    padding: 2% 2%;
  }
}
@media only screen and (max-width: 480px) {
  .applyMain {
    display: block;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
    padding: 50px 0;
  }
  /deep/ {
    .Footer .aboutBox .aboutDL2 {
      padding-bottom: 0;
    }
    .fixedFooter {
      display: none;
    }
  }
  .isApplet {
    padding: 20px 0;
  }
}
@media only screen and (min-height: 850px) {
  .applyMain {
    display: flex;
    height: calc(100vh - 330px);
    box-sizing: content-box;
  }
  .Footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
  }
}
</style>
