import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      title: '首页'
    },
  },
  {
    path: "/question",
    name: "Question",
    component: () => import(/* webpackChunkName: "question" */ "../views/Question.vue"),
    meta: {
      title: '问卷调查'
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: '关于我们'
    },
  },
  {
    path: "/price",
    name: "Price",
    component: () => import(/* webpackChunkName: "price" */ "../views/Price.vue"),
    meta: {
      title: '服务商城'
    },
  },
  {
    path: "/apply",
    name: "Apply",
    component: () => import(/* webpackChunkName: "apply" */ "../views/Apply.vue"),
    meta: {
      title: '申请试用'
    },
  },
  {
    path: "/details",
    name: "Details",
    component: () => import(/* webpackChunkName: "details" */ "../views/Details.vue"),
    meta: {
      title: '产品介绍'
    },
  },
  {
    path: "/insurance",
    name: "Insurance",
    component: () => import(/* webpackChunkName: "insurance" */ "../views/Insurance.vue"),
    meta: {
      title: '医保介绍'
    },
  },
  {
    path: "/boss",
    name: "Boss",
    component: () => import(/* webpackChunkName: "Boss" */ "../views/Boss.vue"),
    meta: {
      title: '董事长简介'
    },
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: (to) => {
    console.log(to);
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { y: 0 }
    }
  },
  routes,
});

export default router;
