<template>
  <div class="boss-page">
    <div class="mainContent">
      <div class="boss-title">
        <h1>高步云（湖南同云健康科技集团董事长）</h1>
      </div>
      <div class="company">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;高步云，男，同云健康品牌创立者，社区诊所信息化、数字化、智能化革新推进者，湖南同云健康科技有限公司董事长，始终秉承“同心同德，云享安康”的核心理念，促进社区诊所提升疾病治疗能力，身体力行地为诊所赋能、为百姓解决看病难、看病贵的问题。
        </p>
      </div>
      <!-- 简介 -->
      <div class="J-lemma-content">
        <div class="paraTitle">
          <h2>人物简介</h2>
        </div>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;出生于江西省宜春市，祖籍湖南省岳阳市，1998年投身医药行业，2006年起先后创办岳阳同安医药有限公司、郴州同安医药有限公司、湖南同安医药有限公司、湖南大医精诚医疗科技有限公司、湖南同云健康科技有限公司、湖南同云堂大药房有限公司、郴州同云堂大药房有限公司等。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;整合了集团业务，集团业务涉及基层医疗信息化、医药供应链、中医传承等领域，带领集团正全力推进数字化、智能化革新战略，深度聚焦于面向消费者的（To C）业务领域，积极拥抱平台化企业转型契机，以期实现企业的跨越式发展与升级。
        </p>
      </div>
      <!-- 主要贡献 -->
      <div class="J-lemma-content">
        
        <div class="paraTitle">
          <h2>主要贡献</h2>
        </div>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;于2006年8月至2009年3月分别创立岳阳同安、郴州同安、湖南同安，总占地面积近30亩，投资上千万元建设的现代仓储物流平台包括ERP、RF、WMS信息管理系统、自动化传送带、电子标签、自动高架等，可充分满足公司医药物流配送发展需求。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;整合了强大的供应商规模，合作供应厂商达近千家，销售网络覆盖全省十四个市州，为全省各地包括药房、诊所、社区卫生服务机构、医院及商业公司等客户提供优质、快速的医药物流服务。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;同安医药秉承“质量第一、诚信为本”的质量方针，全力打造“同心同德、共享安康”的企业品牌，并不断引领行业标准，力争为我省医药健康产业的发展做出更大贡献。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;于2015年涉足医疗科技大健康领域，创办湖南同云健康科技有限公司，开发出同云诊所信息化系统（CIS），为社区诊所提供全方位增值服务，推动了其现代化转型与运营。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;于2018年创立的大医精诚品牌，专注于中医专病专治与技术传承，通过一系列中医项目，为基层医疗注入权威、高效、可靠的中医力量。借助培训、营销、运营等多维度赋能，助力基层医疗医生成为患者信赖的医生。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;于2023年12月创立同云堂，专注于为广大消费者提供品类丰富、品质优良的医药产品和专业的健康服务的医药电商创新型企业。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;于2024创立同云健康旗下品牌-【同云健康】，以数字化家庭健康档案为核心，打造一站式健康医疗管理平台-同云健康小程序/APP，为其臻选中医特色疗法，提供石墨烯光灸房、穴位埋线诊疗及健康产品，以高科技助力中医标准化，守护每个家庭的健康。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;进一步地，推出同云光灸品牌，以新一代灸疗材料“石墨烯”为核心，释放与人体远红外波段十分接近的6-14微米远红外线，通过“共振+非热效应+能量传递”的方式，为中医治疗提供了全新的思路与手段，推动了中医标准化的进程，以其深层渗透力、高效治疗及全身适用性。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;致力于将同云光灸打造成为中医标准化的典范，通过严格的质量控制与疗效评估，确保每一次治疗都能达到最佳效果。同时，我们也在不断探索与创新，将更多现代科技手段融入中医治疗中，推动中医标准化进程和在医疗大健康领域发展的不断深入。
        </p>
      </div>
      <!-- 社会职务 -->
      <div class="J-lemma-content">
        <div class="paraTitle">
          <h2>社会职务</h2>
        </div>
        <p>长沙市第十三届长沙市政协委员</p>
        <p>长沙市岳阳县商会创会会长</p>
        <p>湖南省医药行业协会副会长</p>
        <p>湖南省个私协会副会长</p>
        <p>长沙市工商联常委、天心区工商联（总商会）副会长</p>
      </div>

      <!-- 荣誉 -->
      <div class="J-lemma-content">
        <div class="paraTitle">
          <h2>荣誉</h2>
        </div>
        <p>长沙改革开放四十年杰出企业家</p>
        <p>长沙市优秀共产党员</p>
        <p>岳阳市第二届优秀特色社会主义事业建设者</p>
        <p>岳阳市十大优秀民营企业家</p>
      </div>
      <!-- 新闻报道 -->
      <div class="J-lemma-content">
        <div class="paraTitle">
          <h2>新闻报道</h2>
        </div>
        <p class="news-text" v-for="item in newsList" :key="item.id">
          <span> {{ item.id }}. </span>
          <a :href="item.url">
            {{ item.name }}
          </a>
          <span>{{ item.source }}</span>
        </p>
      
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "Boss",

  data() {
    return {
      newsList: [
        {id:1, name:'得“天”独厚 孕育“心”生机 奋进在高质量发展的“春天里”',source:'· 红网[引用日期2023-03-27]',url:'https://baijiahao.baidu.com/s?id=1759692835577692678'},
        {id:2, name:'长沙市工商联启动集中宣传改革开放40年杰出民营企业家活动',source:'· 红网 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATPXYzP30Y33EMN6v7LXRAeNzzqIPmGapB4brQpt88tI78PJzWgjF_5stMoZEzqf6A1RE6egXce81R7A8xTKi'},
        {id:3, name:'关于表彰2010年度全市十优民营企业家的通报 · 岳阳工业和信息化局',source:'· 岳阳工业和信息化局 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATKaLmvn4Yy7AP9Sv7eXbU7ZzzqIP0XOpQpbyFJEn5c4-8PApEwTd_ptsL4FGxL_lWRQbqqJONYI2Q7wklHThC3eflQ'},
        {id:4, name:'同安医药高步云：紧跟医改方向 推进企业规模化发展',source:'．红网 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATPyPnfX3Ny_AYov6v-HTUbJzzqIPmGapB4zkU4I74d8-8bllFQLPpdZhb9tahbejXkZE6PQTdus0R7Qin37_VjrDwL3m6Z5n2NwH49MXDe8B0a6zuwSv'},
        {id:5, name:'2007年度全市非公有制经济工作先进集体和第二届岳阳市优秀中国特色社会主义事业建设者名单',source:'· 岳阳市 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATPzazv31MSmRNImkt7LQB7VzzqIP0XOpX5nyFJEn5c4-8PApEwTd_ptsL8EQlKf4D0xF8fUVee0qQLIhnGn9VDDA1u25oZ1kxJUroIhDXqkTmam16xg'},
        {id:6, name:'长沙市天心区工商业联合会（商会）第六届执行委员会兼职副主席（副会长）候选人提名人选公示 - 通知公告 - 天心区政府门户网站',source:'· 长沙市天心区 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATP2LzamkMCmRP4z_vubQA7ZzzqIP0XOpX5nyFJw74dkn9_kpEwTd_ptsL8wCkePyFQoBurBYObpuH-k93CG8DyyGg-mx_NwumNFGoopGR-kVh_PwtkH91kyD0LTQszjjijzZu9k'},
        {id:7, name:'政协长沙市第十三届委员会委员名单 - 湘声报社',source:'· 湘声报社 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATKbZzvzxZnuRZ9Sl6-WHV7ZzzqIP0XOpX5nyFJA74dk47f9iGgzJsZcsY9kY2eauFBEGrvhJJeA0RrwhmA'},
        {id:8, name:'长沙市岳阳县商会正式成立',source:'· 长沙市岳阳县 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATKeOmfv0OnvCY9iou7WFBLVzzqIP0XOpX5nyFJEr-Jk48eEpFwWE4883OI5axbD7CEtavahONbhrBtojn3P2UjrK1-aiooU'},
        {id:9, name:'长沙市工商业联合会',source:'· 长沙市工商业联合会 [引用日期2022-04-24]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATPSMzPj3ZynCM9Slv7yGVONzzqIP0XOpS532XZs-rtQ3__lgBwPK_p9tdpgWmKeiQwEP8a9ZOLEqEfI9'},
        {id:10, name:'宜春隆重表彰一批优秀教师和尊师重教先进个人-宜春频道-中国江西网首页',source:'· 中国江西网 [引用日期2022-04-28]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATPLeyPX1MC_CMN75t7OGW7tzzqIPmGapB4T9Q4t86s8x--B0WgjEvdZhbpkGj_u-XxZa7PcRefI1S6ojnGn_UjTDyLvh-NEv2YkA_tY'},
        {id:11, name:'“筑梦天心·致敬新时代” 听非公企业家讲“初心”故事-天心区-长沙晚报网',source:'· 长沙晚报网 [引用日期2022-04-28]',url:'https://baike.baidu.com/reference/60975707/533aYdO6cr3_z3kATPKLmfjwMSeVMdv-7L3UAbtzzqIPmGapB5nyTcY748Qo_LlkGwaEuNczMIZFxbHlCEtE5vYQc-sqR7Mnm3b3TWuGlOI'},
      ],
    };
  },
  components: {
    Footer,
  },
  methods: {},
};
</script>

<style lang="scss">
body {
  background: rgb(250, 250, 250);
}
</style>
<style lang="scss" scoped>
.mainContent {
  background-color: #fff;
  margin: 0 auto;
  text-align: left;
  margin-top: 85px;
  padding: 30px 29px;
  width: 890px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.boss-title {
  h1 {
    color: #000;
    display: block;
    font-size: 34px;
    font-weight: 700;
    line-height: 38px;
    margin-right: 10px;
    position: relative;
    word-break: break-all;
    margin-bottom: 10px;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    color: #333;
    font-family: PingFang SC;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.company {
  margin-bottom: 30px;
  span {
    display: inline-block;
    color: #333;
    font-size: 14px;
    line-height: 24px;
  }
}
.basicInfo {
  display: flex;
  flex-wrap: wrap;

  .itemWrapper {
    width: 50%;
    display: flex;
    margin-bottom: 10px;
    dt {
      width: 80px;
      color: #999;
    }
  }
}

.J-lemma-content {
  padding: 0 30px;
  .paraTitle {
    align-items: center;
    border-left: 12px solid #4f9cee;
    display: flex;
    font-size: 22px;
    line-height: 24px;
    margin: 35px 0 15px -30px;
    h2 {
      margin-left: 10px;
    }
  }
  .titleLine {
    background: #e0e0e0;
    flex: auto;
    height: 1px;
  }
}
.news-text {
  margin-bottom: 10px;
  a {
    text-decoration: underline;
  }
}
</style>
